@use "../../assets/styles/mixins" as mixin;

.contactForm {
  width: 100%;
  // max-width: 600px;
  background: white;
  border-radius: 0.5rem;
  margin-top: 3rem;
  box-shadow: var(--box-shadow);

  button {
    display: flex;
    justify-content: center;
  }
}
