@use "../../assets/styles/mixins" as mixin;

.portfolio {
  padding: 10rem 1.6rem;
  // color: #fff;
  @include mixin.xs {
    font-size: 1.2rem;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    position: relative;
  }
  li {
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    margin: 1rem;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: relative;
    max-height: 22rem;
    height: 22rem;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
      background-color: black;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: absolute;
      opacity: 0;
      transition: 0.7s;
      z-index: 100;
    }

    &:hover::before {
      opacity: 0.9;
    }

    img {
      width: 100%;
      transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
      height: 20rem;
      object-fit: cover;
    }
  }
}

.experiences {
  &__elemText {
    background: var(--background-electromagnetic);
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    color: #fff;
    left: 0;
  }
}

.infos {
  color: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0;
  z-index: 1000;
  transition: all 0.3s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  &:hover {
    opacity: 1;
  }

  &__tech {
    span {
      position: absolute;
      top: 5px;
      left: 5px;
      font-weight: bold;
      color: #fff;
      background-color: black;
      opacity: 0.9;
      padding: 0px 5px;
    }
    p {
      position: relative;
      text-transform: uppercase;
      font-size: 11px;
      border: 1px solid white;
      padding: 10px;
    }
  }

  &__links {
    display: flex;
    i {
      font-size: 26px;
      margin: 0.5rem;
      color: #fff;
    }
  }
}
