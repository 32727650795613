@use "./mixins" as mixin;

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-130 {
  padding-top: 130px;
}

.m-5 {
  margin: 5px;
}

.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}

.m-10 {
  margin: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.m-20 {
  margin: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.my-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.my-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.borderBotom--gray {
  border-bottom: 1px solid #ccc;
}

.with-border-bottom {
  border-bottom: 0.125em solid #c3c6d1;
}

.text-center {
  text-align: center;
}

.d-none-xs {
  @include mixin.xs {
    display: none;
  }
}
