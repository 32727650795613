@use "../../assets/styles/mixins" as mixin;

.contact {
  // padding: 10rem 1.6rem;
  display: flex;
  justify-content: center;
  @include mixin.xs {
    font-size: 1.2rem;
  }
}
