.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-fill {
  flex: 1 1 auto;
}

.align-items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
