@use "../../assets/styles/mixins" as mixin;
@use "../../assets/styles/animations" as animation;

.heroContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero {
  background: var(--background-electromagnetic);
  color: white;
  height: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--separator {
    background: var(--primary);
    @include mixin.size-WH(50%, 1px);
    margin: 0 auto 1.5rem;
  }

  &--content {
    text-align: center;

    h1 {
      font-size: 5.1rem;
      letter-spacing: 2rem;

      @include mixin.xs {
        font-size: 2.3rem;
      }
    }

    p {
      font-size: 2.8rem;
      letter-spacing: 0.1rem;
      font-weight: 300;
      padding: 1.5rem 0 3rem 0;
      @include animation.moveInLeft(2s);
      text-transform: none;

      span {
        font-weight: 700;
      }
    }
  }
}
