.social {
  display: flex;
  justify-content: center;

  i {
    font-size: 26px;
    margin: 0.5rem;
  }

  a {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
    transition: transform 0.4s;
  }
  a:not(:first-child) {
    margin-left: 2rem;
  }
  a:nth-child(1) {
    color: var(--primary);
  }
  a:nth-child(2) {
    color: var(--color-linkedin);
  }
  a:hover {
    transform: scale(2);
  }
}
