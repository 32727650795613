.backToTop {
  position: fixed;
  bottom: 30px;
  right: 20px;
  height: 30px;
  width: 30px;
  font-size: 30px;
  z-index: 1001;
  color: #0984e3;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #74b9ff;
    border-radius: 50%;
  }

  &i:before {
    border-radius: 50%;
    box-shadow: var(--box-shadow);
  }
}
