@use "../../assets/styles/mixins" as mixin;

.about {
  padding: 10rem 1.6rem;
  @include mixin.xs {
    font-size: 1.2rem;
  }

  &--picture {
    padding-right: 2.5rem;
    transform: scaleX(-1);
    @include mixin.lg {
      img {
        height: 31rem;
        width: 233rem;
        object-fit: cover;
      }
    }
    @include mixin.xl {
      img {
        height: 31rem;
        width: 233rem;
        object-fit: cover;
      }
    }

    @include mixin.xs {
      transform: scaleX(1);
      display: flex;
      justify-content: center;
    }
    @include mixin.sm-lt {
      transform: scaleX(1);
      display: flex;
      justify-content: center;
    }
    @include mixin.md-lt {
      transform: scaleX(1);
      display: flex;
      justify-content: center;
    }
    @include mixin.md {
      transform: scaleX(1);
      display: flex;
      justify-content: center;
    }
  }
  &--content {
    position: relative;
    display: flex;
    align-items: center;

    h3 {
      align-items: center;
    }

    @include mixin.xs {
      flex-direction: column;
    }
    @include mixin.sm {
      flex-direction: column;
    }
    @include mixin.md {
      flex-direction: column;
    }

    span {
      font-weight: 700;
    }
  }

  &--contact {
    display: flex;
    justify-content: center;
    button {
      @include mixin.xs {
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
    a {
      transition: all 0.3s ease-in-out;
      color: white;
      &:hover {
        color: var(--background-electromagnetic);
      }
      i {
        margin-right: 0.5rem;
      }
    }

    @include mixin.xs {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
