.header {
  background: var(--background-electromagnetic);
  box-shadow: var(--box-shadow);
  overflow: auto;
  line-height: 1.7em;
  position: fixed;
  width: 100%;
  z-index: 1001;
  height: 6rem;
}
