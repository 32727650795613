//*===== Variables
:root {
  --font-family: "Roboto", sans-serif;

  //*===== colors
  --background: #eee;
  // --background-hero: #2d2d2d;
  --background-electromagnetic: #3c6382;
  --background-blueNights: #0a3d62;
  --color-black: #000;
  --color-white: #e6e7e9;
  --gray: #555;
  --grey-dark: #100303;
  --text: #333;
  --primary: #a4b0be;

  --success: #2dd6b7;
  --info: #56c6e6;
  --warning: #ffbb13;
  --danger: #f0183c;

  //*===== box-shadow
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);

  //*===== Social Media Icons Color
  --color-github: #313131;
  --color-linkedin: white;
  //*===== z index
  --z-fixed: 1000;
}
