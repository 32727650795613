@use "../../assets/styles/mixins" as mixin;

.skills {
  @include mixin.xs {
  }
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    position: relative;
    overflow: hidden;
  }
  li {
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    margin: 1rem;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: relative;
    max-height: 22rem;
    height: 20rem;
    overflow: hidden;
    // width: 100%;

    img {
      width: 100%;
      filter: contrast(50%);
      transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
      height: 82%;
      object-fit: cover;
      min-height: 82%;
      &:hover {
        filter: contrast(100%);
      }
    }

    div {
      background: var(--background-electromagnetic);
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 0;
    }
  }
}
