/* REST */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /*10px*/
}

body {
  margin: 0;
}
p {
  margin: 1rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 2rem 0;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}
