@use "./mixins" as mixin;
// Typography

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  font-size: 1.6rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.title {
  @include mixin.font(3.6rem, 4rem);

  padding: 0 0 22px;
  margin: 0 0 5rem;
  position: relative;

  span {
    color: var(--warning);
  }

  &:after {
    @include mixin.elements(auto, auto, 11px, 0);
    @include mixin.size-WH(55px, 5px);
    background-color: var(--primary);
    z-index: 1;
  }
}

.title-sub {
  font-size: 2rem;
  position: relative;
}

.separator {
  display: flex;
  background: rgba(118, 135, 102, 0.3);
  @include mixin.size-WH(50%, 1px);
  margin: 50px auto 20px;
  // responsive
  @include mixin.sm {
    display: none;
  }
  @include mixin.xs {
    margin: 0 auto 2rem;
  }
}
