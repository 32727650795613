/**
 * ----------------------------------------
 * animation 
 * ----------------------------------------
 */
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

// bounce
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

// animation mx

@mixin moveInLeft($duration) {
  animation: moveInLeft $duration ease-out forwards;
}
