@use "./mixins" as mixin;
.btn {
  &,
  &:link,
  &:visited {
    // display: flex;
    display: block;
    align-items: center;
    cursor: pointer;
    font-size: 1.4rem;
    padding: 5px 9px;
    border-radius: 0.4rem;
    transition: 0.2s opacity;
    font-weight: 600;
    white-space: nowrap;
    border: none;
    outline: none;
    width: auto;
    text-overflow: ellipsis;
    background: none;
    text-transform: inherit;
  }
}

.btn-primary {
  background-color: var(--primary);
  color: white;
  border: 2px solid var(--primary);
}

// scroll down
.scroll-down {
  opacity: 1;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -16px;
  display: block;
  @include mixin.size-WH(32px, 32px);
  border: 2px solid #fff;
  border-radius: 50%;
  animation: bounce 2s infinite 2s;
  transition: all 0.2s ease-in;
  transform: scale(1);

  &:hover {
    border: 2px solid var(--primary);
  }
  @include mixin.xs {
    display: none;
  }
}

.scroll-down:before {
  @include mixin.elements(calc(50% - 8px), 0, 0, calc(50% - 6px));
  @include mixin.size-WH(12px, 12px);
  transform: rotate(-45deg);
  display: block;
  border: 2px solid white;
  border-width: 0px 0 2px 2px;
}

.active {
  background: var(--primary);

  @include mixin.xs {
    width: 100%;
  }
}

// skills
#skills,
#contact {
  padding: 10rem 1.6rem;
  background: var(--background-electromagnetic);
  color: white;
}

// form
label {
  display: none;
}
label[id="persoData"] {
  display: inline-block;
  color: #000;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

input {
  padding: 10px 15px;
  border: 1px solid var(--gray);
  border-radius: 3px;
}

.form-error {
  margin: 0;
  margin-bottom: 0.5rem;
  color: red;
  font-weight: 500;
  font-size: 13px;
}

textarea {
  border-radius: 0.3rem;
  resize: vertical;
  margin-bottom: 1rem;
  padding: 1rem;
}

// nav menu
nav {
  ul {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-around;
    @include mixin.md-lt {
      display: block;
    }
    li a {
      color: #fff;
      display: inline-block;
      padding: 15px;
      text-transform: uppercase;
      transition: all 0.2s;
      width: 100%;
    }
  }

  @include mixin.md-lt {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: var(--background-electromagnetic);
    transition: 1s;
    transform: translateY(-100vh);
    text-align: center;
  }
}

.nav {
  &__btn {
    @include mixin.md-lt {
      position: absolute;
      right: 0;
      visibility: visible;
      opacity: 1;
    }
    padding: 15px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--color-white);
    visibility: hidden;
    opacity: 0;
    font-size: 25px;

    &--open {
      position: fixed;
      top: 0;
      right: 2rem;
      @include mixin.md-lt {
        display: block !important;
      }
    }
    &--close {
      @include mixin.md-lt {
        position: absolute;
        top: 0rem;
        right: 2rem;
      }
    }
  }
}

.responsive_nav {
  @include mixin.md-lt {
    transform: none;
    z-index: 1;
    height: 100vh;
  }
}
