@use "./mixins" as mixin;
.b1 {
  background-color: red;
  border: 1px solid red;
}

.b2 {
  background-color: green;
  border: 1px solid green;
}

.b3 {
  background-color: blue;
  border: 1px solid blue;
}

.b4 {
  background-color: purple;
  border: 1px solid purple;
}

.br {
  @include mixin.xs {
    border: 2px solid red;
  }
  @include mixin.sm {
    border: 2px solid green;
  }
  @include mixin.md {
    border: 2px solid blue;
  }
  @include mixin.lg {
    border: 2px solid orange;
  }
  @include mixin.xl {
    border: 2px solid purple;
  }
}
